import React, { Component } from 'react'
import Link from '../utils/link'
import { navigate } from 'gatsby'
// import Recaptcha from 'react-google-recaptcha'

// const RECAPTCHA_KEY = `6LdSDiEpAAAAAH3bp4r93c63DKozscImr_v0Q_H5`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    enquiry: '',

    otherTextField: false,
  }

  componentDidMount() {
    this.setState({ pathname: window.location.pathname })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // handleRecaptcha = value => {
  //   this.setState({ "g-recaptcha-response": value });
  // }

  handleSubmit = e => {
    e.preventDefault()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        document.querySelector('.contact-form__close').click()
        navigate('/thank-you/')
      })
      .catch(error => this.setState({ result: "fail" }))
  }

  render() {
    let props = {
      ref: "form",
      name: "contact",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    return (
      <form {...props}>
        <div className='form__radio-group'>
          <div className='form__radio'>
            <input type='radio' id='contact-enquiry' name="type" value="Enquiry" onChange={this.handleChange} required />
            <label htmlFor='contact-enquiry'>General Enquiry</label>
          </div>
          <div className='form__radio'>
            <input type='radio' id='contact-free-health-check' name="type" value="Free Health Check" onChange={this.handleChange} />
            <label htmlFor='contact-free-health-check'>IT Health Check</label>
          </div>
          <div className='form__radio'>
            <input type='radio' id='security-assessment' name="type" value="Security Assessment" onChange={this.handleChange} />
            <label htmlFor='security-assessment'>Security Assessment</label>
          </div>  
        </div>
        <div className="form__row form__row--double">
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            onChange={this.handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Business Email"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            type="text"
            name="number"
            placeholder="Contact Number"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            type="text"
            name="company"
            placeholder="Company Name"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row">
          <textarea name="message" onChange={this.handleChange} placeholder='Additional Information' required></textarea>
        </div>
        <div className="form__row form__row--submit">
          <button type="submit" className="btn">
            Submit
          </button>
        </div>
      </form>
    )
  }
}

export default ContactForm