import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import RightArrow from '../assets/images/right-arrow.svg'

class Footer extends Component {
  render() {

    let { services, techContact, salesContact } = this.props

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__logos'>
              <Link to='/' title='Acclaim IT' className='footer__logo'>
                <img src={Logo} alt='Acclaim IT' />
              </Link>
            </div>
            <div className='footer__content'>
              <h4>Services</h4>
              <div className='footer__services'>
                { services &&
                  <ul>
                    { services.map((service, s) => (
                      <li key={s}><Link to={service.link}>{service.text}</Link></li>
                    ))}
                  </ul>
                }
              </div>
              <div className='footer__contact'>
                <h4>Contact <img src={RightArrow} alt='Acclaim IT - Contact' /></h4>
                <h3>{salesContact.title}</h3>
                <ul>
                  <li><Link to={salesContact.phone.link}>{salesContact.phone.text}</Link></li>
                  <li><Link to={salesContact.email.link}>{salesContact.email.text}</Link></li>
                </ul>
                <h3>Technical Support</h3>
                <ul>
                  <li><Link to={techContact.phone.link}>{techContact.phone.text}</Link></li>
                  <li><Link to={techContact.email.link}>{techContact.email.text}</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footer__bottom'>
            <div className='footer__links'>
              <ul>
                <li><Link to='/privacy/'>Privacy</Link></li>
                <li><Link to='/terms-conditions/'>Term and Conditions </Link></li>
              </ul>
              <ul>
                <li><Link to='https://atollon.com.au'>Design by Atollon</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
