import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo } from './icons'
import ContactForm from './contact-form'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: true,
    contact: false,
    cyber: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    const currentScroll = window.pageYOffset
    if (currentScroll > 0) {
      this.setState({ 
        scrolled: true,
      })
    } else {
      this.setState({ 
        scrolled: false
      })
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled, contactForm, cyber } = this.state
    let { mainMenu, services, contact, techContact, salesContact } = this.props

    let props = {
      onClick: this._hideOffCanvas,
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--off-canvas'

    return (
      <>
        <header className={headerClass} onMouseEnter={() => this.setState({ scrolled: true })} onMouseLeave={() => this.setState({ scrolled: false })}>
          <div className='header__inner'>
            <Link to='/' title='Acclaim IT' className='header__logo' {...props}>
              <Logo color={`#1F5EEB`} />
            </Link>
            <nav className='header__nav'>
              { mainMenu &&
                <ul>
                  { mainMenu.map((el, i) => (
                    <li className={el.text === 'Services' ? 'services' : ''} key={i}>
                      { el.text !== 'Contact' && <Link className={(el.text === 'Client Portal' || el.text === 'Remote Support') ? 'btn' : ''} to={el.link} {...props}>{el.text}</Link> }
                      { el.text === 'Contact' && <button id="contact-button" type='button' onClick={() => this.setState({ contactForm: !contactForm })}>{el.text}</button> }
                      { el.text === 'Services' && 
                        <div className='dropdown'>
                          { services &&
                            <ul>
                              { services.map((service, s) => (
                                <li key={s}>
                                  { service.text !== 'Cyber Security' && <Link to={service.link} {...props}>{service.text}</Link> }
                                  { service.text === 'Cyber Security' && <button className={`cyber-button ${cyber ? 'active' : ''}`} onClick={() => this.setState({ cyber: !cyber })}>{service.text} <span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="5" width="2" height="12" fill="#215EEC"/><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="#215EEC"/></svg></span></button> }
                                  { service.text === 'Cyber Security' &&
                                    <ul className={cyber ? 'active' : ''}>
                                      <li><Link to='/essential-8/' {...props}>Essential 8 Compliance</Link></li>
                                      <li><Link to='/cyber-security-awareness/' {...props}>Security Awareness Training</Link></li>
                                      <li><Link to='/cyber-security-melbourne/' {...props}>Cyber Security Melbourne</Link></li>
                                    </ul>
                                  }
                                </li>
                              ))}
                            </ul>
                          }
                        </div>
                      }
                    </li>
                  ))}
                </ul>
              }
            </nav>
            <Link className='header__call' to='tel:1300420955'>Call 1300 420 955</Link>
            <button onClick={this._toggleOffCanvas} className={`header__hamburger ${offCanvas ? 'active' : ''}`}>
              <span className='lines' />
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              { mainMenu &&
                <ul>
                  { mainMenu.map((el, i) => (
                    <li className={el.text === 'Services' ? 'services' : ''} key={i}>
                      { el.text !== 'Contact' && <Link className={el.text === 'Client Portal' ? 'btn' : ''} to={el.link} {...props}>{el.text}</Link> }
                      { el.text === 'Contact' && <button type='button' onClick={() => this.setState({ contactForm: !contactForm })}>{el.text}</button> }
                      { el.text === 'Services' && 
                        <ul>
                          { services.map((service, s) => (
                            <li key={s}>
                              { service.text !== 'Cyber Security' && <Link to={service.link} {...props}>{service.text}</Link> }
                              { service.text === 'Cyber Security' && <button className={`cyber-button ${cyber ? 'active' : ''}`} onClick={() => this.setState({ cyber: !cyber })}>{service.text} <span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="5" width="2" height="12" fill="#215EEC"/><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="#215EEC"/></svg></span></button> }
                              { service.text === 'Cyber Security' &&
                                <ul className={cyber ? 'cyber-dropdown active' : 'cyber-dropdown'}>
                                  <li><Link to='/essential-8/' {...props}>Essential 8 Compliance</Link></li>
                                  <li><Link to='/cyber-security-awareness/' {...props}>Security Awareness Training</Link></li>
                                  <li><Link to='/cyber-security-melbourne/' {...props}>Cyber Security Melbourne</Link></li>
                                </ul>
                              }
                            </li>
                          ))}
                        </ul>
                      }
                    </li>
                  ))}
                </ul>
              }
            </nav>
            <div className='off-canvas__contact'>
              <h3>{salesContact.title}</h3>
              <ul>
                <li><Link to={salesContact.phone.link}>{salesContact.phone.text}</Link></li>
                <li><Link to={salesContact.email.link}>{salesContact.email.text}</Link></li>
              </ul>
              <h3>Technical Support</h3>
              <ul>
                <li><Link to={techContact.phone.link}>{techContact.phone.text}</Link></li>
                <li><Link to={techContact.email.link}>{techContact.email.text}</Link></li>
              </ul>
            </div>
            <div className='off-canvas__links'>
              <ul>
                <li><Link to='/privacy/'>Privacy</Link></li>
                <li><Link to='/terms-conditions/'>Terms &amp; Conditions</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`contact-form ${contactForm ? 'active' : ''}`}>
          <div className='contact-form__wrapper'>
            <button type='button' className='contact-form__close' onClick={() => this.setState({ contactForm: !contactForm })}>
              Close
            </button>
            <div className='contact-form__contact'>
              <h4 dangerouslySetInnerHTML={{ __html: contact.title }} />
              <div dangerouslySetInnerHTML={{ __html: contact.content }} />
              <div className='contact-form__bottom contact-form__bottom--desktop'>
                <h3>{salesContact.title}</h3>
                <ul>
                  <li><Link to={salesContact.phone.link}>{salesContact.phone.text}</Link></li>
                  <li><Link to={salesContact.email.link}>{salesContact.email.text}</Link></li>
                </ul>
                <h3>Technical Support</h3>
                <ul>
                  <li><Link to={techContact.phone.link}>{techContact.phone.text}</Link></li>
                  <li><Link to={techContact.email.link}>{techContact.email.text}</Link></li>
                </ul>
              </div>
            </div>
            <div className='contact-form__form'>
              <ContactForm />
            </div>
            <div className='contact-form__bottom contact-form__bottom--mobile'>
              <h3>{salesContact.title}</h3>
              <ul>
                <li><Link to={salesContact.phone.link}>{salesContact.phone.text}</Link></li>
                <li><Link to={salesContact.email.link}>{salesContact.email.text}</Link></li>
              </ul>
              <h3>Technical Support</h3>
              <ul>
                <li><Link to={techContact.phone.link}>{techContact.phone.text}</Link></li>
                <li><Link to={techContact.email.link}>{techContact.email.text}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
