import React, {Component} from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="151" height="24" viewBox="0 0 151 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
        <path d="M35.4819 8.69812C36.6423 8.69812 37.6282 9.00854 38.4395 9.62938C39.2526 10.2512 39.8215 11.1389 40.0468 12.1374H42.7143C42.3904 10.3377 41.6027 8.92344 40.3514 7.89452C39.1 6.8656 37.5088 6.35114 35.5776 6.35114C33.9897 6.35114 32.5875 6.69927 31.371 7.39553C30.1601 8.08275 29.1727 9.10397 28.5265 10.3372C27.8496 11.6002 27.5111 13.057 27.5111 14.7078C27.5111 16.3585 27.8433 17.8211 28.5076 19.0957C29.1368 20.3336 30.1073 21.3654 31.3043 22.0693C32.5034 22.7762 33.8955 23.1296 35.4804 23.1296C37.43 23.1296 39.0377 22.6103 40.3035 21.5717C41.5684 20.5341 42.372 19.1044 42.7143 17.2825H40.0787C39.8875 18.3042 39.3267 19.2198 38.5034 19.8543C37.6882 20.4751 36.681 20.7856 35.4819 20.7856C33.9182 20.7856 32.649 20.2232 31.6742 19.0986C30.698 17.9729 30.212 16.4876 30.212 14.7107C30.212 12.9337 30.698 11.4861 31.6742 10.3721C32.6504 9.25804 33.9167 8.69812 35.4819 8.69812Z" fill={color} />
        <path d="M52.7434 8.69812C53.9039 8.69812 54.8897 9.00854 55.7011 9.62938C56.5142 10.2512 57.083 11.1389 57.3083 12.1374H59.9758C59.6519 10.3377 58.8642 8.92344 57.6129 7.89452C56.3616 6.8656 54.7703 6.35114 52.8391 6.35114C51.2513 6.35114 49.8491 6.69927 48.6326 7.39553C47.4217 8.08275 46.4342 9.10397 45.788 10.3372C45.1111 11.6002 44.7726 13.057 44.7726 14.7078C44.7726 16.3585 45.1048 17.8211 45.7692 19.0957C46.3983 20.3336 47.3688 21.3654 48.5658 22.0693C49.765 22.7762 51.157 23.1296 52.742 23.1296C54.6915 23.1296 56.2987 22.6103 57.5636 21.5717C58.8304 20.5341 59.6345 19.1044 59.9758 17.2825H57.3402C57.1486 18.304 56.5879 19.2194 55.7649 19.8543C54.9497 20.4751 53.9425 20.7856 52.7434 20.7856C51.1797 20.7856 49.9105 20.2232 48.9357 19.0986C47.9595 17.9729 47.4736 16.4876 47.4736 14.7107C47.4736 12.9337 47.9595 11.4861 48.9357 10.3721C49.9119 9.25804 51.1783 8.69812 52.7434 8.69812Z" fill={color} />
        <path d="M65.6011 0.437256H62.9655V22.9367H65.6011V0.437256Z" fill={color} />
        <path d="M82.5754 19.335V12.2636C82.5754 10.3566 82.002 8.89399 80.8551 7.87571C79.7082 6.85742 78.0845 6.34877 75.9841 6.34973C74.0114 6.34973 72.4095 6.79989 71.1785 7.70019C69.9474 8.6005 69.2367 9.85378 69.0462 11.46H71.6803C71.763 11.0511 71.9266 10.6628 72.1615 10.3181C72.3964 9.9733 72.6979 9.67899 73.0482 9.45246C73.788 8.94767 74.7251 8.69575 75.8594 8.69672C77.1455 8.69672 78.1479 8.9965 78.8664 9.59606C79.5849 10.1956 79.9422 11.0321 79.9383 12.1055V13.2297H75.1153C72.9742 13.2297 71.3409 13.6692 70.2153 14.5482C69.0897 15.4273 68.5269 16.6921 68.5269 18.3429C68.5269 19.8224 69.0786 20.9901 70.1819 21.8459C71.2853 22.7018 72.748 23.1297 74.5698 23.1297C76.9691 23.1297 78.8011 22.2192 80.066 20.3983C80.0863 21.2338 80.3218 21.8658 80.7724 22.2942C81.223 22.7226 81.9734 22.9372 83.0236 22.9382H84.4742V20.5187H83.6677C82.9395 20.5187 82.5754 20.1241 82.5754 19.335ZM79.9354 16.2541C79.9354 17.6466 79.4742 18.7664 78.5516 19.6136C77.6291 20.4607 76.3651 20.8837 74.7599 20.8828C73.6681 20.8828 72.8002 20.6362 72.1561 20.143C71.8488 19.9185 71.6005 19.6228 71.4328 19.2812C71.265 18.9396 71.1827 18.5623 71.193 18.1819C71.193 16.36 72.4148 15.4495 74.8585 15.4505H79.9354V16.2541Z" fill={color} />
        <path d="M87.7467 0.210985C87.3677 0.22379 87.0047 0.366804 86.7188 0.615946C86.4329 0.865087 86.2416 1.20513 86.1771 1.57882C86.1126 1.95251 86.1789 2.337 86.3647 2.66756C86.5505 2.99811 86.8446 3.25453 87.1974 3.39363C87.5502 3.53273 87.9401 3.54601 88.3015 3.43124C88.6629 3.31647 88.9738 3.08066 89.1817 2.76352C89.3896 2.44638 89.4818 2.06728 89.4429 1.69007C89.404 1.31286 89.2363 0.960586 88.968 0.692568C88.808 0.533303 88.6171 0.408428 88.4071 0.325613C88.1971 0.242798 87.9723 0.203791 87.7467 0.210985Z" fill={color} />
        <path d="M89.0637 6.5441H86.4281V22.9368H89.0637V6.5441Z" fill={color} />
        <path d="M115.375 8.08601C114.229 6.92557 112.735 6.34535 110.892 6.34535C108.281 6.34535 106.384 7.38491 105.203 9.46404C104.796 8.48483 104.079 7.66634 103.162 7.13445C102.206 6.5969 101.124 6.32447 100.027 6.34535C98.0127 6.34535 96.4171 7.16008 95.2402 8.78953L94.8877 6.53973H92.6016V22.9309H95.2358V14.2813C95.2358 12.6316 95.627 11.298 96.4093 10.2807C97.1916 9.26338 98.2476 8.75423 99.5773 8.75327C100.841 8.75327 101.816 9.17151 102.502 10.008C103.187 10.8445 103.53 12.0533 103.53 13.6344V22.9179H106.166V14.1421C106.166 12.4701 106.557 11.152 107.339 10.1879C108.122 9.22373 109.198 8.74021 110.568 8.73731C111.812 8.73731 112.771 9.15507 113.446 9.99059C114.121 10.8261 114.459 12.0349 114.461 13.617V22.9077H117.096V13.4864C117.096 11.0437 116.523 9.24355 115.377 8.08601H115.375Z" fill={color} />
        <path d="M12.0483 0.474976L5.93712 11.6703C5.84254 11.8439 5.79474 12.0392 5.7984 12.2368C5.80206 12.4345 5.85707 12.6278 5.95801 12.7978C6.05896 12.9678 6.20237 13.1087 6.37418 13.2065C6.54599 13.3043 6.74029 13.3558 6.938 13.3559H14.1284V15.9915H4.9696C4.54876 15.9912 4.13562 16.1043 3.77365 16.319C3.41169 16.5337 3.11428 16.842 2.91272 17.2114L2.14103 18.633L-0.239319 22.9991H2.76477L4.20661 20.3577L5.14947 18.6301H16.7698V10.7231H9.45905L13.6105 3.11207H14.1356L21.149 15.9668L24.9828 22.9933H27.9855L15.6993 0.474976H12.0483Z" fill={color} />
        <path d="M131.144 0.566376H128.34V23.1297H131.144V0.566376Z" fill={color} />
        <path d="M134.626 0.727386V3.144H141.459V23.1297H144.263V3.144H151.097V0.727386H134.626Z" fill={color} />
        </g>
      </svg>
    )
  }
}

class Tick extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.3361 19.5283C40.3361 19.0058 40.1514 18.4853 39.7841 18.116L36.9909 15.3542C36.6236 14.9869 36.1305 14.7708 35.61 14.7708C35.0895 14.7708 34.5964 14.9849 34.2291 15.3542L21.7068 27.8451L14.7709 20.9092C14.4036 20.5419 13.9105 20.3258 13.39 20.3258C12.8695 20.3258 12.3764 20.5399 12.0091 20.9092L9.21588 23.671C8.84856 24.0383 8.66392 24.5608 8.66392 25.0833C8.66392 25.6058 8.84856 26.0969 9.21588 26.4642L20.3259 37.5742C20.6932 37.9415 21.2157 38.1576 21.7068 38.1576C22.2293 38.1576 22.7498 37.9435 23.1191 37.5742L39.7841 20.9092C40.1514 20.5419 40.3361 20.0488 40.3361 19.5283ZM48.0714 24.4999C48.0714 37.5133 37.5134 48.0713 24.5 48.0713C11.4866 48.0713 0.928558 37.5133 0.928558 24.4999C0.928558 11.4865 11.4866 0.928467 24.5 0.928467C37.5134 0.928467 48.0714 11.4865 48.0714 24.4999Z" fill={color}/>
      </svg>
    )
  }
}

class Arrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.8542 8.86329L18.308 5.44704H0.296875V4.72706H18.308L14.8542 1.31082L15.3691 0.801514L19.7017 5.0871L15.3691 9.37268L14.8542 8.86329Z" fill={color} />
      </svg>
    )
  }
}

class Play extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.786865 0.0180664L6.02367 2.99679L11.2131 6.02367L6.02367 9.00303L0.786865 12.0299V0.0180664Z" fill={color} />
      </svg>
    )
  }
}

class Sound extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.11198H2V12.78H0V5.11198Z" fill={color} />
        <path d="M5 2.55597H7V12.78H5V2.55597Z" fill={color} />
        <path d="M10 0H12V12.78H10V0Z" fill={color} />
      </svg>
    )
  }
}

class ScrollArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="12" height="52" viewBox="0 0 12 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.669193 44.8885L5.15714 49.4258L5.15714 0H6.10298L6.10298 49.4258L10.5909 44.8885L11.26 45.5649L5.63 51.2568L0 45.5649L0.669193 44.8885Z" fill={color} />
      </svg>
    )
  }
}

export { Logo, Arrow, Play, Sound, ScrollArrow, Tick }